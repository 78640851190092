<template>
  <div class="app-container">
    <el-form :inline="true" :model="addForm" class="demo-form-inline">
      <el-form-item label="风险词">
        <el-input v-model="addForm.keyword"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="commitKeyword(addForm.keyword)" type="success">
          添加
        </el-button>
      </el-form-item>
    </el-form>
    <div class="app-box">
      <div class="left">
        <div class="title">
          近期高风险推文
        </div>
        <div class="text">
          <div v-for="(item, index) in this.tweetsList" :key="index">
            {{ index + 1 }}: {{ item }}
          </div>
        </div>
      </div>
      <div class="right">
        <el-table
          :data="tableData"
          style="width: 100%"
          v-loading="listLoading"
          border
          fit
          highlight-current-row
          class="tableList3"
        >
          <el-table-column prop="id" label="ID" />
          <el-table-column prop="keyword" label="关键词"></el-table-column>
          <el-table-column
            prop="create_time"
            label="上架时间"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="removeKeyword(scope.row.id)"
                type="text"
                size="small"
              >
                删除关键词
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageIndex"
          :page-sizes="[pageSize, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRiskKeyword,
  addRiskKeyword,
  deleteRiskKeyword,
  getExceptionTwitterPostTasks
} from '@/api/twitter'
export default {
  data() {
    return {
      addForm: { keyword: '' },
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      showShoppingAssign: false,
      showShoppingTitle: null,
      shopping: null,
      addshopping: [],
      listLoading: true,
      tweetsList: []
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    addRiskKeyword,
    // 获取服务列表
    async fetchData() {
      this.listLoading = true
      const { items, all_count: total } = await getRiskKeyword({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      })
      this.tableData = items
      this.total = total
      this.listLoading = false

      const { items: taskItems } = await getExceptionTwitterPostTasks()
      // console.log('ExceptionPostTasks', data)
      const tweets = []
      for (let i = 0; i < taskItems.length; ++i) {
        tweets[i] = taskItems[i].content
      }
      this.tweetsList = tweets
    },
    async commitKeyword(keyword) {
      await addRiskKeyword(keyword)
      await this.fetchData()
      this.addForm.keyword = ''
    },
    async removeKeyword(id) {
      await deleteRiskKeyword(id)
      await this.fetchData()
    },
    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.pageIndex = val
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  height: calc(100vh - 84px);
  .app-box {
    display: flex;
    justify-content: space-between;
    .left {
      width: calc(50% - 10px);
      .title {
        font-size: 20px;
        font-weight: 600;
      }
      .text {
        margin-top: 10px;
        height: calc(100% - 40px);
        overflow: auto;
        > div {
          line-height: 25px;
          margin-bottom: 15px;
        }
      }
    }
    .right {
      width: calc(50% - 20px);
      ::v-deep .el-table__body-wrapper {
        height: calc(100vh - 300px) !important;
      }
    }
  }
}
</style>
